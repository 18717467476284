import React from "react"
import { Cookie, getCookie, setCookie } from "../cookies"

export class CookiePreferences extends React.Component {
  render() {
    const isStatisticsChecked = getCookie(Cookie.STATISTICS) === "true"

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>
          <label>
            <input type="checkbox" disabled checked></input> Necessary cookies
          </label>
        </span>
        <span>
          <label>
            <input
              type="checkbox"
              defaultChecked={isStatisticsChecked}
              onChange={e => {
                if (e.target.checked) {
                  setCookie(Cookie.STATISTICS, "true")
                } else {
                  setCookie(Cookie.STATISTICS, "false")
                }
              }}
            ></input>{" "}
            Statistics cookies
          </label>
        </span>
      </div>
    )
  }
}
